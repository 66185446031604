import { PageNames }    from "../../Routes/routes";
import { WishContact }  from "../../Models/Form/ContactUs";
import { CollapseStep } from "../../ViewModel/Car/SelectDirectionViewModel";
import { MethodsPay }   from "../../config/MethodsPay";
import { TYPE_DNI }     from "../../config/typeDNI";
import { OrderStatus }  from "../../config/orderStatus";
import { ErrorsCards }  from "../../config/errorsCards";

/**
 * Default Language
 */
const es = {
    car           : {
        "add"                 : "Agregar",
        "add-all"             : "Agregar todos",
        "car-to-buy"          : "Carrito de Compra",
        "color"               : "Color",
        "continue"            : "Continuar",
        "direction"           : "Dirección",
        "empty-car"           : "Tu carrito esta vacio",
        "generate-order"      : "Generar Orden",
        "generated-order"     : "Orden Generada",
        "method-pay"          : "Método de pago",
        "method-transport"    : "Método de transporte",
        "need-bill-electronic": "Factura Electronica",
        "out-stock"           : "Agotado",
        "price"               : "Precio",
        "price-unity"         : "Precio unitario",
        "procced-to-pay"      : "Proceder a pagar",
        "producto"            : "Producto",
        "quantity"            : "Cantidad",
        "shipping"            : "Transporte",
        "subtotal"            : "SubTotal",
        "total"               : "Total",
        "total-price"         : "Precio total",
    },
    cards         : {
        "add"               : "Agregar",
        "direction"         : "Dirección",
        "errors"            : {
            [ErrorsCards["Card's security code is not incorrect."]]                                       : "El código de seguridad de la tarjeta no es incorrecto.",
            [ErrorsCards["Your card has expired. Please review it with your Bank."]]                      : "Su tarjeta ha caducado. Revíselo con su banco.",
            [ErrorsCards["Your card was declined. Please review it with your Bank."]]                     : "Su tarjeta fue rechazada. Revíselo con su banco.",
            [ErrorsCards["Your card number is incorrect. Please check the values."]]                      : "Su número de tarjeta es incorrecto. Compruebe los valores.",
            [ErrorsCards["AMEX is not supported for CRC currency. You must use Visa or MasterCard card"]] : "AMEX no es compatible. Debe utilizar una tarjeta Visa o MasterCard",
            [ErrorsCards["There was an error procesing your payment. Please check all given information"]]: "Hubo un error al procesar su pago. Verifique toda la información proporcionada",
            [ErrorsCards["Exceeds your limit amount for charge"]]                                         : "Supera la cantidad límite de cargo",
        },
        "occupations"       : "Ocupaciones",
        "select"            : "Seleccionar",
        "select-direction"  : "Seleccionar Dirección",
        "selected"          : "Seleccionado",
        "selected-direction": "Dirección seleccionada",
        "show-more"         : "Ver más"
    },
    categories    : {
        default   : "Sin Categoria",
        disponible: "Sin Categoria",
    },
    color         : {
        "none"      : "Ninguno",
        "pick-color": "Color:"
    },
    dni           : {
        [TYPE_DNI.CEDULA]   : "Cédula Física",
        [TYPE_DNI.JURICA]   : "Cédula Jurídica",
        [TYPE_DNI.DIMEX]    : "DIMEX",
        [TYPE_DNI.PASAPORTE]: "NITE",
    },
    footer        : {
        "all-rights-reserved": "© {{year}} All rights reserved.",
        "back-to-top"        : "Volver a arriba",
        "content"            : "Somos una empresa enfocada en la venta de materiales para la construcción, ferretería ,eléctricidad, fontanería, maderas, aceros , pintura, agregados, mallas para perímetros y herramienta eléctrica, manual y estacionaria .",
        "information"        : "Información"
    },
    forms         : {
        "bill-electronic-saved"    : "Información guardada.",
        "canton"                   : "Canton",
        "direction"                : "Dirección",
        "direction-saved"          : "Dirección Salavada",
        "dni"                      : "Identificación",
        "email"                    : "Email",
        "exact-direction"          : "Dirección Exacta",
        "forget-password"          : "Olvido su contraseña?",
        "fullName"                 : "Nombre Completo",
        "lastName"                 : "Apellidos",
        "message"                  : "Mensaje",
        "name"                     : "Nombre",
        "password"                 : "Contraseña",
        "password-confirm"         : "Confirme Contraseña",
        "phone"                    : "Teléfono",
        "principal-bill-electronic": "Utilizar como mi datos de facturación digital principal",
        "principal-direction"      : "Utilizar como mi dirección de envío principal",
        "province"                 : "Provincia",
        "search"                   : "Buscar",
        "search-products"          : "Buscar en los productos",
        "sent"                     : "Su mensaje fue enviado.",
        "submit"                   : "enviar",
        "type-dni"                 : "Tipo de Identificación",
        [WishContact.whatsApp]     : "WhatsApp",
        "wish-contact"             : "¿Cómo desea ser contáctado?"
    },
    global        : {
        "actual-price"               : "Precio Actual",
        "after"                      : "Despúes",
        "before"                     : "Antes",
        "categories"                 : "Categorias",
        "company"                    : "Maderas Camacho S.A.",
        "companyName"                : "Ferreteria Maderas Camacho",
        "credit-card"                : {
            cardNumber: "Numero de tarjeta",
            cvc       : "CVC",
            expiry    : "Fecha de expiracion",
            name      : "Nombre",
        },
        "date"                       : "Fecha",
        "description"                : "Descripción",
        "detail"                     : "Detalles",
        "forget-password-not-found"  : "No se encontro un correo electrónico asociado a la cuenta.",
        "forget-password-success"    : "Se ha enviado un correo electrónico con un enlace para actualizar tu contraseña.",
        "frequently-bought-together" : "Frecuentemente comprado junto",
        "login"                      : "Iniciar Sesión",
        "login-facebook"             : "Iniciar con Facebook",
        "login-google"               : "Iniciar con Google",
        "order"                      : "Orden",
        "order-products"             : {
            createdAt: "Nuevos",
            name     : "Nombre",
            order    : "Defecto",
        },
        "pages"                      : "Páginas",
        "password-changed-successful": "La contraseña ha sido actualizada",
        "price"                      : "Precio",
        "register"                   : "Registrarme",
        "restore-my-password"        : "Restablecer mi contraseña.",
        "save"                       : "Guardar",
        "show-more"                  : "Ver más",
        "sort-by"                    : "Ordenar por",
        "status"                     : "Estado",
        "submit"                     : "Enviar",
        "token-invalid-or-not-exist" : "El token ya expiro o es invalido",
        "total"                      : "Total",
    },
    header        : {},
    menu          : {
        [PageNames.homePage]                : "Principal",
        [PageNames.promotionsPage]          : "Promociones",
        [PageNames.promotionPage]           : "Promoción",
        [PageNames.tutorialPage]            : "Tutoriales",
        [PageNames.whoWePage]               : "Quiénes Somos",
        [PageNames.recommendationPersonPage]: "Recomendaciones",
        [PageNames.contactPage]             : "Contáctenos",
        [PageNames.productsPage]            : "Productos",
        [PageNames.policyPrivacy]           : "Politicas de Privacidad",
    },
    "methods-pay" : {
        [MethodsPay.CASH]    : "Efectivo",
        // [MethodsPay.CARD]    : "Tarjeta",
        [MethodsPay.TRANSFER]: "Transferencia Bancaria",
    },
    "order-status": {
        [OrderStatus.canceled]     : "Cancelado",
        [OrderStatus.confirm]      : "Confirmado",
        [OrderStatus.shipped]      : "Enviado",
        [OrderStatus.readyToPickup]: "Pedido listo para retirar",
        [OrderStatus.delivered]    : "Entregado",
    },
    page          : {
        "about-us"              : "Acerca de nosotros",
        "contact-us"            : "Contáctenos",
        "home"                  : {
            "last-products": "Ultimos Productos",
            "products"     : "Productos",
        },
        "last-promotions"       : "Ultimas promociones",
        "order"                 : {
            [CollapseStep.METHOD_TRANSPORT]    : "Método de Transporte",
            [CollapseStep.METHOD_PAY]          : "Método de Pago",
            [CollapseStep.CHOOSE_DIRECTION]    : "Dirección",
            [CollapseStep.DATA_BILL_ELECTRONIC]: "Factura Electronica",
            "detail-order"                     : "Detalles de la order:",
            "my-car"                           : "En el carrito",
            "my-orders"                        : "Mis Ordenes",
            "new-order"                        : "Orden Generada",
            "new-order-content"                : "Muy pronto recibira un correo electrónico",
            "order"                            : "Orden:",
            "order-date"                       : "Ordenado el:",
            "review-order"                     : "Revise su pedido",
            "select-bill-electronic"           : "Factura Electronica",
            "select-direction"                 : "Seleccion una dirección",
        },
        "our-brands"            : "Nuestras Principales Marcas",
        "profile"               : {
            "bill-electronic": "Factura Electronica",
            "edit-profile"   : "Editar mi Perfil",
            "hello"          : "Hola,",
            "mine-directions": "Mis Direcciones",
            "mine-packages"  : "Mis Órdenes",
            "packages"       : "Órdenes",
            "see-packages"   : "Ver órdenes",
            "who-actions"    : "¿Qué Deseas Hacer?",
        },
        "promotion"             : {
            "information-promotion": "Información de la promoción",
            "other-promotions"     : "Otras promociones",
        },
        "promotions"            : "Promociones",
        "recommendation-persons": {
            "paragraphs": [
                "Este es un directorio gratuito para el anunciante de servicios relacionados con la construcción, reparacion y otros servicios q ofrecen nuestros clientes.",
                "Maderas Camacho S.A no tiene ninguna responsabilidad de los trabajos realizados por las personas anunciantes y oferentes de servicios en este directorio, ya que el contrato o vínculo de servicio que obtenga cualquier persona quién visite esta sección de la página y el oferente de servicios es privado entre el anunciante y la persona que solicita el servicio.",
                "De igual forma agradecemos los comentarios de los contratos realizados una vez concluido el trabajo del anunciante, esto será de gran ayuda para futuros clientes y para el anunciante.",
            ],
            "subtitle"  : "Estimado visitante es importante que lea esto:",
            "title"     : "Recomendaciones"
        },
        "register"              : {},
        "tutorials"             : "Tutoriales",
    },
    validator     : {
        "field-bill-electronic"        : "Los datos de la facturacion facturación es requerida.",
        "field-direction"              : "La dirección es requerida.",
        "field-email"                  : "El correo electrónico es invalido.",
        "field-min"                    : "El :attribute debe tener al menos 6 caracteres..",
        "field-name"                   : "El :attribute es requerido.",
        "field-password"               : "El :attribute es requerido.",
        "field-password-confirm"       : "Debe confirmar la contraseña.",
        "field-password-confirm-repeat": "Las contraseñas no coinciden",
        "field-password-no-regex"      : "La contraseña debe contener: Una letra mayuscula, una letra minuscula, un numero y debe ser mayor a 8 carácteres.",
        "field-required"               : "El :attribute es requerido.",
        "password-incorrect"           : "Password Incorrecto",
        "user-exist"                   : "Ya existe otro usuario con el correo",
        "user-not-exist"               : "El usuario no exist",
    },

};

export default es;
