import { custom, list, object, serializable } from "serializr";
import { observable }                         from "mobx";
import { Direction }                          from "../User/Direction";
import { MethodsPay }                         from "../../config/MethodsPay";
import { date }                               from "../../Serializer/date";
import { MethodTransport }                    from "../MethodTransport/MethodTransport";
import User                                   from "../User/User";
import { BillElectronic }                     from "../User/BillElectronic";
import { ProductItemCar }                     from "../ProductItemCar/ProductItemCar";
import { OrderStatus }                        from "../../config/orderStatus";
import { CalculateShipping }                  from "../CalculateShipping/CalculateShipping";
import { CreditCard }                         from "./CreditCard";

export class Order {
    @serializable
    @observable
    private _id: string;

    @serializable(object(Direction))
    @observable
    private direction: Direction;

    @serializable(object(User))
    @observable
    private user: User;

    @serializable
    @observable
    private methodPay: MethodsPay = MethodsPay.CASH;

    @serializable
    @observable
    private methodTransportId: string = "";

    @serializable
    @observable
    private status: OrderStatus;

    @serializable
    @observable
    private identifier: string = "";

    @serializable
    @observable
    private total: number;

    @serializable
    @observable
    private subTotal: number;

    @serializable
    @observable
    private isBillElectronic: boolean = false;

    @serializable(object(BillElectronic))
    @observable
    private billElectronic: BillElectronic;

    @serializable(object(MethodTransport))
    @observable
    private methodTransport: MethodTransport;

    @serializable(object(CalculateShipping))
    @observable
    private calculateShipping: CalculateShipping;

    @serializable(object(CreditCard))
    @observable
    private creditCard: CreditCard = new CreditCard();

    @observable
    @serializable(list(object(ProductItemCar)))
    private productStore: ProductItemCar[] = [];

    @serializable(custom(date.serializer, date.deserializer))
    @observable
    private createdAt: Date;

    @serializable(custom(date.serializer, date.deserializer))
    @observable
    private updatedAt: Date;

    public getCreatedAt(): Date {
        return this.createdAt;
    }

    public setCreatedAt(value: Date) {
        this.createdAt = value;
    }

    public getUpdatedAt(): Date {
        return this.updatedAt;
    }

    public setUpdatedAt(value: Date) {
        this.updatedAt = value;
    }

    public getId(): string {
        return this._id;
    }

    public setId(value: string) {
        this._id = value;
    }

    public getDirection(): Direction {
        return this.direction;
    }

    public setDirection(value: Direction) {
        this.direction = value;
    }

    public getMethodPay(): MethodsPay {
        return this.methodPay;
    }

    public setMethodPay(value: MethodsPay) {
        this.methodPay = value;
    }

    public getMethodTransportId(): string {
        return this.methodTransportId;
    }

    public setMethodTransportId(value: string) {
        this.methodTransportId = value;
    }

    public getMethodTransport(): MethodTransport {
        return this.methodTransport;
    }

    public setMethodTransport(value: MethodTransport) {
        this.methodTransport = value;
        this.setMethodTransportId(this.getMethodTransport().getId());
    }

    public getUser(): User {
        return this.user;
    }

    public setUser(value: User) {
        this.user = value;
    }

    public getIsBillElectronic(): boolean {
        return this.isBillElectronic;
    }

    public setIsBillElectronic(value: boolean) {
        this.isBillElectronic = value;

        // @ts-ignore
        this.setBillElectronic(undefined);
    }

    public getBillElectronic(): BillElectronic {
        return this.billElectronic;
    }

    public setBillElectronic(value: BillElectronic) {
        this.billElectronic = value;
    }

    public getProductStore(): ProductItemCar[] {
        return this.productStore;
    }

    public setProductStore(value: ProductItemCar[]) {
        this.productStore = value;
    }

    public getIdentifier(): string {
        return this.identifier;
    }

    public setIdentifier(value: string) {
        this.identifier = value;
    }

    public getTotal(): number {
        return this.total;
    }

    public setTotal(value: number) {
        this.total = value;
    }

    public getSubTotal(): number {
        return this.subTotal;
    }

    public setSubTotal(value: number) {
        this.subTotal = value;
    }

    public getStatus(): OrderStatus {
        return this.status;
    }

    public setStatus(value: OrderStatus) {
        this.status = value;
    }

    public getCalculateShipping(): CalculateShipping {
        return this.calculateShipping;
    }

    public setCalculateShipping(value: CalculateShipping) {
        this.calculateShipping = value;
    }

    public returnSubTotal(): number {
        return this.getProductStore().reduce((accum: number, item: ProductItemCar) => {
            return accum + (item.getProduct().getPrice() * item.getQuantity());
        }, 0);
    }

    public getDistance(): number {
        const calculateShipping = this.getCalculateShipping();
        if (Array.isArray(calculateShipping)) return 0;

        return calculateShipping ? calculateShipping.getKms() : 0;
    }


    public getCreditCard(): CreditCard {
        return this.creditCard;
    }

    public setCreditCard(value: CreditCard) {
        this.creditCard = value;
    }
}